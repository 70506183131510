import { order } from '@/api'
export default {
    name: 'go-view',

    created () {
        // 判断是否有type 参数
        if (!this.$route.query.sn) {
            this.$toast('缺少订单号')
            return setTimeout(() => { this.$router.push({ name: 'Find' }) }, 500);
        }

        // 获取数据
        this.handleGetData()
    },

    methods: {
        handleGetData () {
            order.show({
                sn: this.$route.query.sn
            }).then(res => {
                if (res.status != 1) return this.$toast(res.message)
                let data = res.data
                if (data.status == 1) return this.$router.push({name: 'Answer', query: {sn: data.sn, t: data.product.title}})
                if (data.status == 0) return this.$router.push({ name: 'Payment', query: { sn: data.sn, t: data.product.title } })
            }).catch(err => {
                this.isAjax = false
                console.log(err)
            })
        }
    },

    data() {
        return {
            isAjax: false,
            data: null,
        }
    }

}